import React from "react"
import { Gallery } from "gatsby-theme-gallery";

import Layout from "../components/layout"
import Container from "../components/container"

const MyGallery = () => (
  <Layout>
    <Container>
      <Gallery />
    </Container>
  </Layout>
);

export default MyGallery
